const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const podcastService = {
  async fetchPodcastMetadata(podcastId) {
    const response = await fetch(`${API_BASE_URL}/get_podcast_metadata/${podcastId}`);
    if (!response.ok) throw new Error('Failed to fetch podcast data');
    return response.json();
  },

  async fetchTranscript(podcastId) {
    const response = await fetch(`https://d6i8.c16.e2-5.dev/podcast-transcripts/${podcastId}.json`);
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
    return response.json();
  },

  async generatePodcast(formData) {
    const response = await fetch(`${API_BASE_URL}/generate_podcast`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        text_url: formData.url,
        language: 'German',
        male_name: formData.maleSpeaker,
        female_name: formData.femaleSpeaker,
        language_level: formData.languageLevel,
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response;
  },

  async trackPodcastGeneration(hashId) {
    const response = await fetch(
      `${API_BASE_URL}/generate_podcast_tracker?hash_id=${hashId}`
    );
    if (!response.ok) throw new Error('Polling failed');
    return response.json();
  }
}; 