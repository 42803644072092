export const Skeleton = ({
    rows = 1,
    linesPerRow = 3,
    className = "",
    rowClassName = "mb-4",
    lineClassName = "h-3",
    firstLineWidth = "w-24 md:w-32 h-4",  // Responsive widths
    lineWidths = [
      "w-full",
      "w-11/12 md:w-10/12",
      "w-4/5 md:w-3/4"
    ],
    containerClassName = "w-full max-w-full px-4 md:px-6 lg:px-8" // Responsive container
  }) => {
    return (
      <div className={`animate-pulse ${containerClassName} ${className}`}>
        {Array.from({ length: rows }, (_, rowIndex) => (
          <div key={rowIndex} className={`${rowClassName}`}>
            {/* First line (header/title) with responsive width */}
            <div className={`bg-gray-200 rounded ${firstLineWidth} mb-2`} />
            
            {/* Subsequent lines with responsive widths */}
            <div className="space-y-2">
              {Array.from({ length: linesPerRow }, (_, lineIndex) => (
                <div
                  key={lineIndex}
                  className={`bg-gray-200 rounded ${lineClassName} ${lineWidths[lineIndex % lineWidths.length]}`}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };