import { useCallback } from 'react';

export const useMediaSource = (audioRef) => {
  const setupMediaSource = useCallback(async (response) => {
    const reader = response.body.getReader();
    const { done, value } = await reader.read();
    
    if (done) {
      throw new Error('No data received from the stream.');
    }

    return new Promise((resolve, reject) => {
      const mediaSource = new MediaSource();
      audioRef.current.src = URL.createObjectURL(mediaSource);

      mediaSource.addEventListener('sourceopen', () => {
        try {
          const mimeCodec = 'audio/mpeg';
          const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
          resolve({ mediaSource, sourceBuffer, firstChunk: value, reader });
        } catch (error) {
          reject(error);
        }
      });

      mediaSource.addEventListener('error', (e) => {
        reject(new Error('MediaSource error: ' + e.message));
      });
    });
  }, [audioRef]);

  const processStream = useCallback(async ({ mediaSource, sourceBuffer, firstChunk, reader }) => {
    if (!mediaSource || !sourceBuffer) {
      console.log('MediaSource or SourceBuffer not initialized');
      return;
    }

    let queue = [firstChunk];
    let isAppending = false;
    let isReading = true;
    let isEnded = false;

    const processQueue = () => {
      if (isAppending || queue.length === 0 || isEnded) {
        return;
      }
      isAppending = true;
      const chunk = queue.shift();
      try {
        sourceBuffer.appendBuffer(chunk);
      } catch (error) {
        console.error('Error during appendBuffer:', error);
      }
    };

    sourceBuffer.addEventListener('updateend', () => {
      isAppending = false;
      if (queue.length > 0) {
        processQueue();
      } else if (!isReading && !isEnded) {
        if (mediaSource.readyState === 'open') {
          try {
            console.log('Ending stream...');
            mediaSource.endOfStream();
            isEnded = true;
          } catch (error) {
            console.error('Error ending media source stream:', error);
          }
        }
      }
    });

    const readStream = async () => {
      try {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            console.log('Stream finished.');
            isReading = false;
            if (!sourceBuffer.updating && queue.length === 0 && !isEnded) {
              if (mediaSource.readyState === 'open') {
                console.log('Ending stream (final)...');
                mediaSource.endOfStream();
                isEnded = true;
              }
            }
            break;
          }
          queue.push(value);
          processQueue();
        }
      } catch (error) {
        console.error('Error reading stream:', error);
        isReading = false;
      }
    };

    // Start both processes
    processQueue();
    await readStream();
  }, []);

  return { setupMediaSource, processStream };
}; 