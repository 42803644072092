import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          'create_podcast': 'Create Podcast',
          'new_podcast': 'New Podcast',
          'article_url': 'Article URL',
          'url_placeholder': 'Paste news URL or try \'Random Article\' (https://...)',
          'random_article': 'Random Article',
          'loading': 'Loading...',
          'from_news_feed': 'From News Feed',
          'language_level': 'Language Level',
          'elementary': 'Basic Knowledge',
          'intermediate': 'Independent',
          'upper_intermediate': 'Advanced',
          'advanced': 'Competent',
          'podcast_speakers': 'Podcast Speakers',
          'create_podcast_button': 'Create Podcast',
          'search_placeholder': 'Search podcasts...',
          // Form validation messages
          'please_provide_url': 'Please provide a URL',
          'please_enter_valid_url': 'Please enter a valid URL',
          'unable_to_fetch_preview': 'Unable to fetch preview',
          'error_fetching_news': 'Error loading news articles',
        }
      },
      de: {
        translation: {
          'create_podcast': 'Podcast erstellen',
          'new_podcast': 'Neuer Podcast',
          'article_url': 'Artikel-URL',
          'url_placeholder': 'News-URL einfügen oder \'Zufälliger Artikel\' probieren (https://...)',
          'random_article': 'Zufälliger Artikel',
          'loading': 'Lädt...',
          'from_news_feed': 'Aus dem Newsfeed',
          'language_level': 'Sprachniveau',
          'elementary': 'Grundlegende Kenntnisse',
          'intermediate': 'Selbstständig',
          'upper_intermediate': 'Fortgeschritten',
          'advanced': 'Kompetent',
          'podcast_speakers': 'Podcast-Sprecher',
          'create_podcast_button': 'Podcast erstellen',
          'search_placeholder': 'Podcasts durchsuchen...',
          // Form validation messages
          'please_provide_url': 'Bitte geben Sie eine URL ein',
          'please_enter_valid_url': 'Bitte geben Sie eine gültige URL ein',
          'unable_to_fetch_preview': 'Vorschau konnte nicht geladen werden',
          'error_fetching_news': 'Fehler beim Laden der Nachrichtenartikel',
        }
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n; 