// components/AudioPlayer.js

import React, { useEffect } from 'react';
import { ReactComponent as Play } from '../images/play.svg';
import { ReactComponent as Pause } from '../images/pause.svg';
import { useAudioPlayer, SPEED_CONTROLS } from '../hooks/useAudioPlayer';

function AudioPlayer({ audioRef, audioSrc, isPlayable }) {
  const {
    isPlaying,
    playbackSpeed,
    showSpeed,
    handlePlay,
    handleSpeedChange,
  } = useAudioPlayer(audioRef);

  const isMediaSourceSupported = typeof window.MediaSource !== 'undefined';

  useEffect(() => {
    if (audioRef.current && isPlayable && !isMediaSourceSupported) {
      console.log('Non-MediaSource browser: Reloading audio on completion');
      audioRef.current.load();
    }
  }, [isPlayable, audioRef, isMediaSourceSupported]);

  const togglePlay = () => {
    if (!isPlayable) return;
    handlePlay();
  };

  // Calculate if speed buttons should be disabled
  const isDecrementDisabled = !isPlayable || playbackSpeed <= SPEED_CONTROLS.MIN;
  const isIncrementDisabled = !isPlayable || playbackSpeed >= SPEED_CONTROLS.MAX;

  return (
    <>
      <div className='flex items-center justify-center'>
        <button
          title="Decrease playback speed"
          onClick={() => handleSpeedChange(-SPEED_CONTROLS.STEP)}
          disabled={isDecrementDisabled}
          className={`text-sm rounded-full bg-slate-200 w-5 h-5 ${
            !isDecrementDisabled
              ? 'cursor-pointer'
              : 'cursor-not-allowed text-gray-400'
          }`}
        >
          <span>-</span>
        </button>
        <div className='relative mx-1'>
          <button
            onClick={togglePlay}
            disabled={!isPlayable}
            className={`rounded-full mx-2 w-8 h-8 flex items-center justify-center ${
              isPlayable
                ? 'hover:scale-105 cursor-pointer text-black'
                : 'cursor-not-allowed text-gray-200 opacity-40'
            }`}
          >
            <div className={`rounded-full p-2 bg-rose-400 flex item-center justify-center button-play ${isPlayable && !isPlaying ? 'pulse' : ''}`}>
              {isPlaying ? <Pause /> : <Play />}
            </div>
          </button>
          {showSpeed && (
            <div
              className='text-xs absolute left-1/2 -translate-x-1/2 mt-1 transition-opacity duration-1000 opacity-100'
            >
              {playbackSpeed === 1 ? '1x' : `${playbackSpeed.toFixed(1)}x`}
            </div>
          )}
        </div>
        <button
          title="Increase playback speed"
          onClick={() => handleSpeedChange(SPEED_CONTROLS.STEP)}
          disabled={isIncrementDisabled}
          className={`text-sm rounded-full bg-slate-200 w-5 h-5 ${
            !isIncrementDisabled
              ? 'cursor-pointer'
              : 'cursor-not-allowed text-gray-400'
          }`}
        >
          <span>+</span>
        </button>
      </div>
      <audio ref={audioRef} src={audioSrc} style={{ display: 'none' }}></audio>
    </>
  );
}

export default AudioPlayer;
