// components/Transcript.js

import React, { useEffect, useState } from 'react';
import { useFetchTranscript } from '../hooks/useFetchTranscript';
import { useHighlightWord } from '../hooks/useHighlightWord';
import { Skeleton } from './Skeleton';

function Transcript({ podcastId, audioRef }) {
  const { transcriptData, error, isLoading } = useFetchTranscript(podcastId);
  const [allWords, setAllWords] = useState([]);

  useEffect(() => {
    if (transcriptData) {
      const flattenedWords = transcriptData.reduce((acc, turn) => {
        return acc.concat(turn.words);
      }, []);
      setAllWords(flattenedWords);
    }
  }, [transcriptData]);

  const { currentWordIndex, wordRefs } = useHighlightWord(allWords, audioRef);

  const isTranscriptGenerating = error?.status === 202;
  if (isTranscriptGenerating) {
    return (
      <p>
        Transcript will appear here shortly after the podcast generation has
        been completed.
      </p>
    );
  }

  if (error) {
    return (
      <p>
        Error loading transcript: {error.message}
      </p>
    );
  }

  if (isLoading || !transcriptData) {
    return (
      <Skeleton
        rows={3}
        linesPerRow={3}
        firstLineWidth="w-24 md:w-32 lg:w-40 h-4"
        containerClassName="w-full max-w-3xl mx-auto px-4 md:px-6 lg:px-8"
        lineWidths={[
          "w-full",
          "w-11/12 md:w-10/12 lg:w-9/12",
          "w-4/5 md:w-3/4 lg:w-2/3"
        ]}
      />
    );
  }

  return (
    <div className='transcript'>
      {transcriptData.map((turn, turnIndex) => (
        <div key={turnIndex}>
          <p className='speaker-name'>{turn.speaker}:</p>
          <p className='turn-text'>
            {turn.words.map((word) => {
              const isHighlighted = word.globalIndex === currentWordIndex;
              return (
                <span
                  key={word.globalIndex}
                  ref={(el) => {
                    wordRefs.current[word.globalIndex] = el;
                  }}
                  className={isHighlighted ? 'highlight' : ''}
                  style={{ marginRight: '2px' }}
                >
                  {word.text}{' '}
                </span>
              );
            })}
          </p>
        </div>
      ))}
    </div>
  );
}

export default Transcript;
