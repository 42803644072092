// components/PodcastCreationForm.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewsArticles } from '../hooks/useNewsArticles';
import { useLinkPreview } from '../hooks/useLinkPreview';

function PodcastCreationForm({ onSubmit, isCreating }) {
  const { t } = useTranslation();
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState('');
  const [languageLevel, setLanguageLevel] = useState('a2');

  // Enhanced speaker arrays with avatar information
  const maleSpeakers = [
    { name: 'Chris', avatar: '/images/chris.png' },
    { name: 'Otto', avatar: '/images/otto.png' }
  ];

  const femaleSpeakers = [
    { name: 'Mila', avatar: '/images/mila.png' },
    { name: 'Leonie', avatar: '/images/leonie.png' },
  ];

  // Use first speaker from each array as default
  const [maleSpeaker, setMaleSpeaker] = useState(maleSpeakers[0].name);
  const [femaleSpeaker, setFemaleSpeaker] = useState(femaleSpeakers[0].name);

  // Replace news articles state with useNewsArticles hook
  const {
    articles: newsArticles,
    isLoading: isLoadingNews,
    error: newsError,
    selectRandomArticle,
    fetchArticles
  } = useNewsArticles();

  // Update handleRandomArticle to handle the random selection
  const handleRandomArticle = async () => {
    const article = selectRandomArticle();
    if (!article) {
      // If no articles yet, fetch them and select a random one
      const fetchedArticles = await fetchArticles();
      if (newsError) {
        setUrlError(t('error_fetching_news'));
        return;
      }
      if (fetchedArticles.length > 0) {
        const randomIndex = Math.floor(Math.random() * fetchedArticles.length);
        setUrl(fetchedArticles[randomIndex].link);
      }
    } else {
      setUrl(article.link);
    }
  };

  // Add useLinkPreview hook
  const {
    preview: linkPreview,
    isLoading: isLoadingPreview,
    error: previewError
  } = useLinkPreview(url);

  // URL validation
  const validateUrl = () => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!url.trim()) {
      setUrlError(t('please_provide_url'));
      return false;
    } else if (!urlRegex.test(url)) {
      setUrlError(t('please_enter_valid_url'));
      return false;
    } else if (previewError || !linkPreview) {
      setUrlError(t('unable_to_fetch_preview'));
      return false;
    }
    setUrlError('');
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateUrl()) {
      return;
    }
    onSubmit({
      url,
      languageLevel,
      maleSpeaker,
      femaleSpeaker
    });
  };

  return (
    <div className='create-podcast-form p-6 sm:p-10 mt-6 sm:mt-10 mx-auto w-full max-w-lg sm:max-w-xl rounded-md bg-white'>
      <h2 className='text-2xl sm:text-3xl font-bold mb-6 sm:mb-8 text-center'>{t('new_podcast')}</h2>
      <form onSubmit={handleSubmit}>
        <div className="space-y-6 sm:space-y-8">
          {/* URL Section */}
          <div>
            <label htmlFor="url" className="block text-sm font-medium text-gray-700 mb-1">
              {t('article_url')}
            </label>
            <div className="mt-1">
              <input
                type="url"
                name="url"
                id="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                onBlur={validateUrl}
                className={`block w-full px-3 py-2 border ${urlError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder={t('url_placeholder')}
                required
                aria-invalid={!!urlError}
                aria-describedby="url-error"
              />
              {urlError && (
                <p className="mt-2 text-sm text-red-600" id="url-error">
                  {urlError}
                </p>
              )}
            </div>

            <div className="mt-2 flex items-center justify-between">
              <button
                type="button"
                onClick={handleRandomArticle}
                disabled={isLoadingNews}
                className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-md 
                  ${isLoadingNews ? 'bg-gray-100 text-gray-500' : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100'} 
                  transition-colors duration-150 ease-in-out`}
              >
                <span className={`mr-2 ${isLoadingNews ? 'animate-spin inline-block' : ''}`}>↻</span>
                {isLoadingNews ? t('loading') : t('random_article')}
              </button>
              {newsError && (
                <span className="text-sm text-red-600">
                  {t('error_fetching_news')}
                </span>
              )}
              {newsArticles.some(article => article.link === url) && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  {t('from_news_feed')}
                </span>
              )}
            </div>

            {/* Link Preview Section */}
            <div className="mt-4">
              {(isLoadingPreview || linkPreview) && (
                <div className="border rounded-lg overflow-hidden shadow-sm transition-all duration-200 ease-in-out">
                  {isLoadingPreview ? (
                    <div className="animate-pulse flex p-3">
                      <div className="w-16 h-16 bg-slate-200 rounded hidden sm:block"></div>
                      <div className="flex-1 ml-0 sm:ml-3 space-y-2">
                        <div className="h-4 bg-slate-200 rounded w-3/4"></div>
                      </div>
                    </div>
                  ) : linkPreview && (
                    <div className="flex p-3 items-center">
                      {/* Only show image on small screens and above */}
                      {linkPreview.image && (
                        <img 
                          src={linkPreview.image} 
                          alt="" 
                          className="w-16 h-16 object-cover rounded hidden sm:block"
                        />
                      )}
                      <div className="flex-1 ml-0 sm:ml-3">
                        <h3 className="font-medium text-gray-900 text-sm line-clamp-2">
                          {linkPreview.title}
                        </h3>
                        {/* Hide URL on mobile to save space */}
                        <p className="text-xs text-gray-500 truncate mt-1 hidden sm:block">
                          {url}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Divider */}
          <hr className="my-6" />

          {/* Language Level Section */}
          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>
              {t('language_level')}
            </label>
            <select
              value={languageLevel}
              onChange={(e) => setLanguageLevel(e.target.value)}
              className='w-full bg-transparent placeholder:text-gray-400 text-gray-700 text-base border border-gray-300 rounded px-3 py-2 transition duration-300 ease focus:outline-none focus:border-indigo-500 hover:border-gray-400 shadow-sm focus:shadow-md appearance-none cursor-pointer'
              disabled={isCreating}
            >
              <option value='a2'>A2 - {t('elementary')}</option>
              <option value='b1'>B1 - {t('intermediate')}</option>
              <option value='b2'>B2 - {t('upper_intermediate')}</option>
              <option value='c1'>C1 - {t('advanced')}</option>
            </select>
          </div>

          {/* Divider */}
          <hr className="my-6" />

          {/* Podcast Speakers */}
          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>
              {t('podcast_speakers')}
            </label>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
              {/* Male Speaker */}
              <div className="relative">
                <select
                  value={maleSpeaker}
                  onChange={(e) => setMaleSpeaker(e.target.value)}
                  className='w-full bg-transparent placeholder:text-gray-400 text-gray-700 text-base border border-gray-300 rounded pl-10 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-indigo-500 hover:border-gray-400 shadow-sm focus:shadow-md appearance-none cursor-pointer'
                  disabled={isCreating}
                >
                  {maleSpeakers.map(speaker => (
                    <option key={speaker.name} value={speaker.name}>
                      {speaker.name}
                    </option>
                  ))}
                </select>
                <img
                  src={maleSpeakers.find(s => s.name === maleSpeaker)?.avatar}
                  alt=""
                  className="absolute left-2 top-1/2 -translate-y-1/2 w-6 h-6 rounded-full object-cover pointer-events-none"
                />
              </div>

              {/* Female Speaker */}
              <div className="relative">
                <select
                  value={femaleSpeaker}
                  onChange={(e) => setFemaleSpeaker(e.target.value)}
                  className='w-full bg-transparent placeholder:text-gray-400 text-gray-700 text-base border border-gray-300 rounded pl-10 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-indigo-500 hover:border-gray-400 shadow-sm focus:shadow-md appearance-none cursor-pointer'
                  disabled={isCreating}
                >
                  {femaleSpeakers.map(speaker => (
                    <option key={speaker.name} value={speaker.name}>
                      {speaker.name}
                    </option>
                  ))}
                </select>
                <img
                  src={femaleSpeakers.find(s => s.name === femaleSpeaker)?.avatar}
                  alt=""
                  className="absolute left-2 top-1/2 -translate-y-1/2 w-6 h-6 rounded-full object-cover pointer-events-none"
                />
              </div>
            </div>
          </div>

          {/* Create Podcast Button */}
          <div className="flex justify-center mt-6">
            <button
              type='submit'
              disabled={isCreating}
              className='text-white text-base sm:text-lg flex justify-center rounded-full bg-rose-500 px-5 py-2 sm:px-6 sm:py-3 font-semibold leading-6 shadow-sm hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 transition w-full sm:w-auto'
            >
              {t('create_podcast_button')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PodcastCreationForm;
