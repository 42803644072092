import { useState, useEffect } from 'react';
import { linkPreviewService } from '../services/linkPreviewService';

export const useLinkPreview = (url) => {
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPreview = async () => {
      if (!url.trim()) {
        setPreview(null);
        return;
      }

      setIsLoading(true);
      try {
        const data = await linkPreviewService.fetchPreview(url);
        setPreview(data);
        setError(null);
      } catch (error) {
        console.error('Error fetching link preview:', error);
        setPreview(null);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    const timeoutId = setTimeout(fetchPreview, 500);
    return () => clearTimeout(timeoutId);
  }, [url]);

  return {
    preview,
    isLoading,
    error
  };
}; 