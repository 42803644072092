// src/hooks/useFetchTranscript.js

import { useState, useEffect } from 'react';
import { podcastService } from '../services/podcastService';

// Hook to fetch and process transcript data
export const useFetchTranscript = (podcastId) => {
  const [transcriptData, setTranscriptData] = useState(null);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  const MAX_RETRIES = 40;
  const RETRY_INTERVAL = 10000; // 10 seconds

  const processTranscriptData = (data) => {
    const { conversation, timestamps } = data;
    const processedData = [];
    let timestampIndex = 0;
    let globalIndex = 0;

    conversation.forEach((turn) => {
      const speaker = turn.speaker;
      const text = turn.text;
      const words = text.split(/\s+/);
      const wordObjects = [];

      words.forEach((word) => {
        if (timestampIndex < timestamps.length) {
          const timestamp = timestamps[timestampIndex];
          wordObjects.push({
            text: word,
            start: timestamp.start,
            end: timestamp.end,
            hasTiming: timestamp.start !== null && timestamp.end !== null,
            globalIndex: globalIndex,
          });
          timestampIndex++;
        } else {
          wordObjects.push({
            text: word,
            start: null,
            end: null,
            hasTiming: false,
            globalIndex: globalIndex,
          });
        }
        globalIndex++;
      });

      processedData.push({
        speaker: speaker,
        words: wordObjects,
      });
    });

    return processedData;
  };

  useEffect(() => {
    let timeoutId;

    const fetchTranscript = async () => {
      if (!podcastId) return;

      try {
        const data = await podcastService.fetchTranscript(podcastId);
        const processedData = processTranscriptData(data);
        setTranscriptData(processedData);
        setError(null);
        setRetryCount(0);
      } catch (error) {
        console.error('Fetch error:', error);
        if (retryCount < MAX_RETRIES) {
          timeoutId = setTimeout(() => {
            setRetryCount((prev) => prev + 1);
          }, RETRY_INTERVAL);
          setError({
            message: 'Transcript not ready yet, retrying...',
            status: 202  // Using 202 Accepted for "in progress"
          });
        } else {
          setError({
            message: error.message,
            status: error.response?.status || 500
          });
        }
      }
    };

    fetchTranscript();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [podcastId, retryCount]);

  return {
    transcriptData,
    error,
    isLoading: !transcriptData && !error,
  };
};