// PodcastStreamer.js

import React, { useRef, useState, useEffect } from 'react';
import Header from './components/Header';
import PodcastCreationForm from './components/PodcastCreationForm';
import SearchComponent from './components/SearchComponent';
import Modal from './Modal';
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './components/LanguageSwitcher';
import { usePodcastCreation } from './hooks/usePodcastCreation';
import './i18n';

function PodcastStreamer() {
  const { t } = useTranslation();
  const audioRef = useRef(null);

  // Initialize GA
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  }, []);

  // UI state
  const [showCreationView, setShowCreationView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Use podcast creation hook
  const {
    newPodcast,
    progressMessages,
    responseMetadata,
    isPlayable,
    createPodcast
  } = usePodcastCreation(audioRef);

  // Handle form submission
  const handlePodcastCreation = async (formData) => {
    if (!showCreationView) {
      setShowCreationView(true);
      await createPodcast(formData);
    }
  };

  return (
    <>
      <Header />
      <LanguageSwitcher />

      {/* Create New Podcast button */}
      {!showCreationView && !newPodcast && (
        <div className='px-10 pb-4'>
          <button
            onClick={() => setIsModalOpen(true)}
            className='relative lg:absolute mt-8 lg:mt-0 lg:right-10 lg:top-16 text-white m-auto text-lg flex w-full lg:w-56 justify-center rounded-3xl bg-rose-400 px-3 py-3 font-semibold leading-6 dark:text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            {t('create_podcast')}
          </button>
        </div>
      )}

      {/* Modal with PodcastCreationForm */}
      <Modal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <PodcastCreationForm
          onSubmit={(formData) => {
            handlePodcastCreation(formData);
            setIsModalOpen(false);
          }}
          isCreating={showCreationView}
        />
      </Modal>

      {/* SearchComponent */}
      <div className='relative mt-4 lg:mt-24 md:mx-auto max-w-screen-lg p-10 pt-2 lg:pt-10'>
        <SearchComponent
          newPodcast={responseMetadata || newPodcast || null}
          isCreating={showCreationView}
          progressMessages={progressMessages}
          audioRef={audioRef}
          isPlayable={isPlayable}
        />
      </div>
    </>
  );
}

export default PodcastStreamer;
