import { useState, useEffect, useRef } from 'react';

// Hook to handle highlighting the current word in the transcript
export const useHighlightWord = (allWords, audioRef) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(null);
  const wordRefs = useRef({});

  useEffect(() => {
    let rafId;

    const updateCurrentWord = () => {
      if (!audioRef?.current || audioRef.current.readyState < 1) {
        rafId = requestAnimationFrame(updateCurrentWord);
        return;
      }

      if (audioRef.current && allWords.length > 0) {
        const currentTime = audioRef.current.currentTime;
        let index = -1;

        for (let i = 0; i < allWords.length; i++) {
          const word = allWords[i];
          if (word.hasTiming) {
            if (currentTime >= word.start && currentTime < word.end) {
              index = word.globalIndex;
              break;
            }
          }
        }

        if (index !== -1 && index !== currentWordIndex) {
          setCurrentWordIndex(index);
        } else if (index === -1 && currentWordIndex !== null) {
          setCurrentWordIndex(null);
        }

        rafId = requestAnimationFrame(updateCurrentWord);
      }
    };

    rafId = requestAnimationFrame(updateCurrentWord);

    return () => {
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
    };
  }, [currentWordIndex, allWords, audioRef]);

  useEffect(() => {
    if (currentWordIndex !== null && wordRefs.current[currentWordIndex]) {
      const element = wordRefs.current[currentWordIndex];
      const container = element.closest('.transcript');
      if (container) {
        const elementRect = element.getBoundingClientRect();

        // Calculate the scroll position to center the element
        const scrollTop =
          element.offsetTop -
          container.offsetTop -
          container.clientHeight / 2 +
          elementRect.height / 2;

        container.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      }
    }
  }, [currentWordIndex]);

  return {
    currentWordIndex,
    wordRefs,
  };
}; 