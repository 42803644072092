import { useEffect } from 'react';

export const useKeyboardNavigation = (audioRef) => {
  useEffect(() => {
    if (!audioRef?.current) return;  // Add ?.current check here

    console.log('Keyboard navigation hook attached');

    const handleKeyPress = (e) => {
      if (!audioRef?.current) return;  // Add ?.current check here too
      
      switch(e.code) {
        case 'Space':
          e.preventDefault();
          if (audioRef.current.paused) {
            audioRef.current.play();
          } else {
            audioRef.current.pause();
          }
          break;
        case 'ArrowLeft':
          e.preventDefault();
          audioRef.current.currentTime -= 5;
          break;
        case 'ArrowRight':
          e.preventDefault();
          audioRef.current.currentTime += 5;
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      console.log('Keyboard navigation hook detached');
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [audioRef]);
}; 
