import { useMemo } from 'react';
import { liteClient as algoliasearch } from 'algoliasearch/lite';

export const useSearch = () => {
  const searchClient = useMemo(() => 
    algoliasearch(
      process.env.REACT_APP_ALGOLIA_ID,
      process.env.REACT_APP_ALGOLIA_KEY
    ), 
  []);

  const defaultConfig = {
    indexName: 'podcasts',
    hitsPerPage: 20,
  };

  return {
    searchClient,
    defaultConfig
  };
}; 