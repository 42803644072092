// components/SearchComponent.js

import React from 'react';
import { Index, InstantSearch, SearchBox, useHits, Pagination, Configure } from 'react-instantsearch';
import 'instantsearch.css/themes/satellite.css';
import PodcastCard from './PodcastCard';
import { useTranslation } from 'react-i18next';
import { useSearch } from '../hooks/useSearch';

// Custom Hits Component using useHits
const CustomHits = ({ newPodcast, isCreating, progressMessages, audioRef, isPlayable}) => {
  const { hits } = useHits();

  return (
    <div className="ais-Hits-list">
      {/* Render newPodcast as the first item */}
      {newPodcast && (
        <div className="ais-Hits-item">
          <PodcastCard
            podcast={newPodcast}
            isCreating={isCreating}
            progressMessages={progressMessages}
            audioRef={audioRef}
            isPlayable={isPlayable}            
          />
        </div>
      )}
      {/* Render existing hits */}
      {hits.map((hit) => (
        <div className="ais-Hits-item" key={hit.objectID}>
          <PodcastCard podcast={hit} />
        </div>
      ))}
    </div>
  );
};

const SearchPagination = () => (
  <div className="pagination-container">
    <Pagination
      padding={2}
      showFirst={true}
      showPrevious={true}
      showNext={true}
      showLast={true}
      classNames={{
        root: 'pagination',
        list: 'pagination-list',
        item: 'pagination-item',
        selectedItem: 'pagination-item-selected',
        disabledItem: 'pagination-item-disabled',
        firstItem: 'pagination-item-first',
        lastItem: 'pagination-item-last',
        previousItem: 'pagination-item-previous',
        nextItem: 'pagination-item-next',
      }}
    />
  </div>
);

function SearchComponent({
  newPodcast,
  isCreating,
  progressMessages,
  audioRef,
  isPlayable,
}) {
  const { t } = useTranslation();
  const { searchClient, defaultConfig } = useSearch();

  return (
    <InstantSearch 
      searchClient={searchClient} 
      indexName={defaultConfig.indexName}
    >
      <div className="search-container">
        <Configure hitsPerPage={defaultConfig.hitsPerPage} />
        
        <SearchBox
          placeholder={t('search_placeholder')}
        />

        <Index indexName={defaultConfig.indexName}>
          <CustomHits
            newPodcast={newPodcast}
            isCreating={isCreating}
            progressMessages={progressMessages}
            audioRef={audioRef}
            isPlayable={isPlayable}
          />
          <SearchPagination />
        </Index>
      </div>
    </InstantSearch>
  );
}

export default SearchComponent;