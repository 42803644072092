import { useState, useEffect } from 'react';
import { podcastService } from '../services/podcastService';

export const usePodcastData = (podcastId) => {
  const [podcast, setPodcast] = useState(null);
  const [error, setError] = useState(null);
  const [isPlayable, setIsPlayable] = useState(false);

  useEffect(() => {
    const fetchPodcastData = async () => {
      if (!podcastId) return;

      try {
        const data = await podcastService.fetchPodcastMetadata(podcastId);
        setPodcast(data);
        setIsPlayable(true);    
      } catch (error) {
        setError(error.message);
      }
    };

    fetchPodcastData();
  }, [podcastId]);

  return {
    podcast,
    error,
    isPlayable    
  };
}; 