import React from 'react';
import { useParams, Link } from 'react-router-dom';
import PodcastCard from './PodcastCard';
import Header from './Header';
import { usePodcastData } from '../hooks/usePodcastData';

// Internal components
const PageLayout = ({ children }) => (
  <div className="min-h-screen bg-gray-100">
    <Header />
    <div className="max-w-2xl mx-auto mt-8 p-4">
      {children}
    </div>
  </div>
);

const BackToHomeLink = () => (
  <Link to="/" className="text-rose-400 hover:text-rose-500">
    ← Back to home
  </Link>
);

// SharedPodcast component
function SharedPodcast() {
  const { podcastId } = useParams();
  const { podcast, error, isPlayable } = usePodcastData(podcastId);

  if (error) {
    return (
      <PageLayout>
        <h2 className="text-xl font-bold text-red-500 mb-4">
          Invalid or missing podcast data
        </h2>
        <BackToHomeLink />
      </PageLayout>
    );
  }

  if (!podcast) {
    return (
      <PageLayout>
        Loading...
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <BackToHomeLink />
      <PodcastCard
        podcast={podcast}
        isPlayable={isPlayable}        
      />
    </PageLayout>
  );
}

export default SharedPodcast; 