import { useState } from 'react';
import { newsService } from '../services/newsService';

export const useNewsArticles = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const fetchArticles = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await newsService.fetchArticles();
      setArticles(data);
      return data;
    } catch (error) {
      console.error('Error fetching news:', error);
      setError(error.message);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const selectRandomArticle = () => {
    if (articles.length === 0) {
      fetchArticles();
      return null;
    }
    const randomIndex = Math.floor(Math.random() * articles.length);
    return articles[randomIndex];
  };

  return {
    articles,
    isLoading,
    error,
    fetchArticles,
    selectRandomArticle
  };
}; 