// components/PodcastCard.js

import React, { useState } from 'react';
import AudioPlayer from './AudioPlayer';
import Transcript from './Transcript';
import { ReactComponent as TranscriptIcon } from '../images/transcript.svg';
import { ReactComponent as FlipBackIcon } from '../images/flip_back.svg';
import { ReactComponent as ShareIcon } from '../images/share.svg';
import podcastDefaultImage from '../images/podcast.png';

// Internal helper components
const PodcastCardImagePlaceholder = () => (
  <div className='img-container'>
    <div className='img'></div>
  </div>
);

const PodcastCardTextPlaceholder = () => (
  <div className='content'>
    <div className='stripe small-stripe'></div>
    <div className='stripe medium-stripe'></div>
    <div className='stripe long-stripe'></div>
  </div>
);

const PodcastContent = ({ podcast }) => (
  <>
    <h3 className='font-bold mb-1'>{podcast.title || 'Untitled Podcast'}</h3>
    <p className='mb-1 line-clamp-1'>
      {podcast.description || 'No description available'}
    </p>
    <div className='truncate'>
      <a
        href={podcast.text_url || '#'}
        target='_blank'
        rel='noopener noreferrer'
        className='text-slate-500 text-xs'
      >
        {podcast.text_url ? new URL(podcast.text_url).hostname.replace('www.', '') : 'URL not available'}
      </a>
    </div>
  </>
);

const CardControls = ({ handleShare, handleFlip, audioRef, audioSrc, isPodcastPlayable }) => (
  <div className='flex items-center justify-between mt-auto mb-3 overflow-visible'>
    <button
      onClick={handleShare}
      className='share-button p-2 hover:bg-gray-100 rounded-full'
      title="Share podcast"
    >
      <ShareIcon className="h-5 w-5" />
    </button>
    
    <AudioPlayer
      audioRef={audioRef}
      audioSrc={audioSrc}
      isPlayable={isPodcastPlayable}
    />

    <button
      onClick={handleFlip}
      className='flip-button p-2 hover:bg-gray-100 rounded-full'
      title="Show transcript"
    >
      <TranscriptIcon />
    </button>
  </div>
);

const LanguageBadge = ({ level }) => (
  <div className={`${level}-level uppercase font-bold text-white absolute top-2 right-2 rounded-br-md rounded-tl-md p-1 flex items-center justify-center w-8 h-8`}>
    {level}
  </div>
);

const ProgressMessage = ({ message }) => (
  <p className='text-xs text-slate-500 flex items-center justify-center'>
    {message}
  </p>
);

const FlipBackButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className='flip-button absolute bottom-2 right-2'
  >
    <FlipBackIcon />
  </button>
);

const CardFront = ({ podcast, isCreating, progressMessages, handleShare, handleFlip, audioRef, isPodcastPlayable }) => (
  <div className='card-face flip-card-face flip-card-front overflow-visible'>
    <div className='h-36 overflow-hidden'>
      {isCreating && !podcast.image_url ? (
        <PodcastCardImagePlaceholder />
      ) : (
        <img 
          src={podcast.image_url || podcastDefaultImage} 
          alt={podcast.title || "Podcast"} 
          className="w-full h-full object-cover"
        />
      )}
    </div>

    <div className='mt-4'>
      {isCreating && !podcast.title ? (
        <PodcastCardTextPlaceholder />
      ) : (
        <PodcastContent podcast={podcast} />
      )}
    </div>

    <CardControls 
      handleShare={handleShare}
      handleFlip={handleFlip}
      audioRef={audioRef}
      audioSrc={podcast.s3_url}
      isPodcastPlayable={isPodcastPlayable}
    />

    <LanguageBadge level={podcast.language_level || 'a2'} />

    {isCreating && progressMessages.length > 0 && (
      <ProgressMessage message={progressMessages[progressMessages.length - 1]} />
    )}
  </div>
);

const CardBack = ({ podcast, audioRef, handleFlip, isFlipped }) => (
  <div className='card-face flip-card-face flip-card-back'>
    {isFlipped && (
      <Transcript
        podcastId={podcast.objectID}
        audioRef={audioRef}
      />
    )}
    <FlipBackButton onClick={handleFlip} />
    <LanguageBadge level={podcast.language_level || 'a2'} />
  </div>
);


// Main component, put it all together
function PodcastCard({ 
  podcast, 
  isCreating = false, 
  progressMessages = [], 
  audioRef: propAudioRef, 
  isPlayable = true 
}) {
  const [isFlipped, setIsFlipped] = useState(false);
  const localAudioRef = React.useRef(null);
  const audioRef = propAudioRef || localAudioRef;

  const isPodcastPlayable = isCreating 
    ? (isPlayable && podcast.s3_url)
    : true;

  const handleShare = async () => {
    const shareUrl = `${window.location.origin}/shared/${podcast.objectID}`;
    try {
      await navigator.clipboard.writeText(shareUrl);
      // Simple tooltip implementation
      const tooltip = document.createElement('div');
      tooltip.textContent = 'Link copied!';
      tooltip.style.cssText = `
        position: fixed;
        top: 20px;
        right: 20px;
        background: #333;
        color: white;
        padding: 8px 12px;
        border-radius: 4px;
        z-index: 1000;
      `;
      document.body.appendChild(tooltip);
      setTimeout(() => tooltip.remove(), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className='card-container w-full lg:w-72 rounded-md lg:m-auto lg:p-4 relative overflow-visible'>
      <div className={`card flip-card ${isFlipped ? 'is-flipped' : ''} overflow-visible`}>
        <CardFront 
          podcast={podcast}
          isCreating={isCreating}
          progressMessages={progressMessages}
          handleShare={handleShare}
          handleFlip={() => setIsFlipped(true)}
          audioRef={audioRef}
          isPodcastPlayable={isPodcastPlayable}
        />
        <CardBack 
          podcast={podcast}
          audioRef={audioRef}
          handleFlip={() => setIsFlipped(false)}
          isFlipped={isFlipped}
        />
      </div>
    </div>
  );
}

export default PodcastCard;
