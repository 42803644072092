import { useState, useEffect } from 'react';
import { useKeyboardNavigation } from './useKeyboardNavigation';
export const SPEED_CONTROLS = {
  MIN: 0.8,
  MAX: 1.1,
  STEP: 0.1,
  DEFAULT: 1.0,
};

export const useAudioPlayer = (audioRef, speedTimeout = 2000) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(SPEED_CONTROLS.DEFAULT);
  const [showSpeed, setShowSpeed] = useState(false);
  const [isKeyboardEnabled, setIsKeyboardEnabled] = useState(false);

  // Only keep this useEffect for cleanup
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleEnded = () => setIsPlaying(false);
    audio.addEventListener('ended', handleEnded);
    return () => audio.removeEventListener('ended', handleEnded);
  }, [audioRef]);

  const handleSpeedChange = (increment) => {
    const newSpeed = Math.max(
      SPEED_CONTROLS.MIN,
      Math.min(SPEED_CONTROLS.MAX, playbackSpeed + increment)
    );
    setPlaybackSpeed(newSpeed);
    if (audioRef.current) {
      audioRef.current.playbackRate = newSpeed;
    }
    
    // Show speed and set up auto-hide
    setShowSpeed(true);
    setTimeout(() => setShowSpeed(false), speedTimeout);
  };

  // Play/pause toggle
  const handlePlay = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
      setIsKeyboardEnabled(false);  // Disable keyboard when paused
    } else {
      audio.play();
      setIsPlaying(true);
      setIsKeyboardEnabled(true);   // Enable keyboard when playing
    }
  };

  // Hook up keyboard navigation when enabled
  useKeyboardNavigation(isKeyboardEnabled ? audioRef : null);

  return {
    isPlaying,
    playbackSpeed,
    showSpeed,
    handlePlay,
    handleSpeedChange,
    SPEED_CONTROLS,
  };
};
