import { useState, useCallback, useRef, useEffect } from 'react';
import { blake2b } from 'blakejs';
import ReactGA from "react-ga4";
import { useMediaSource } from './useMediaSource';
import { podcastService } from '../services/podcastService';

export const usePodcastCreation = (audioRef) => {
  const [isCreating, setIsCreating] = useState(false);
  const [newPodcast, setNewPodcast] = useState(null);
  const [progressMessages, setProgressMessages] = useState([]);
  const [responseMetadata, setResponseMetadata] = useState(null);
  const [isPlayable, setIsPlayable] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isReadyToPlay, setIsReadyToPlay] = useState(false);
  const pollTimeoutRef = useRef(null);
    
  const isMediaSourceSupported = typeof window.MediaSource !== 'undefined';
  
  const { setupMediaSource, processStream } = useMediaSource(audioRef);

  const handlePodcastStatus = useCallback((data) => {
    const message = data.message;
    const messageToShow = message === 'Ready to play' && !isMediaSourceSupported
      ? "Audio is generating, please wait..."
      : message;
    
    setProgressMessages(prev => [...prev, messageToShow]);
    
    if (message === 'Ready to play' && isMediaSourceSupported) {
      setIsPlayable(true);
    } else if (message === 'Completed') {
      setIsPlayable(true);
      setIsProcessing(false);
    } else if (message.startsWith('Error')) {
      alert(message);
      setIsProcessing(false);
      setIsPlayable(false);
    }
  }, [isMediaSourceSupported]);

  const handlePodcastMetadata = useCallback((data) => {
    const processedData = {
      ...data,
      image_url: data.image_url || '/podcast.png',
      title: data.title || 'No title available',
    };
    setResponseMetadata(processedData);
  }, []);

  const poll = useCallback(async (hashId) => {
    if (!hashId) {
      console.log('Polling stopped - no hashId provided');
      return;
    }
    
    try {
      console.log('Making polling request...');
      const events = await podcastService.trackPodcastGeneration(hashId);
      console.log('Received events:', events);
      
      let shouldContinue = true;
      for (const event of events) {
        switch (event.event) {
          case 'podcastStatus':
            handlePodcastStatus(event.data);
            if (event.data.message === 'Completed' || event.data.message.startsWith('Error')) {
              shouldContinue = false;
            }
            break;
          case 'podcastMetadata':
            handlePodcastMetadata(event.data);
            break;
          default:
            console.warn('Unknown event type:', event.event);
        }
      }

      if (shouldContinue) {
        pollTimeoutRef.current = setTimeout(() => poll(hashId), 1000);
      }
    } catch (error) {
      console.error('Polling error:', error);
      alert(error.message);
      setIsProcessing(false);
      setIsPlayable(false);
    }
  }, [handlePodcastStatus, handlePodcastMetadata]);

  const computeHash = async (text) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const fullHash = blake2b(data, null, 8);
    return Array.from(new Uint8Array(fullHash))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  };

  const startStreaming = async (formUrl, languageLevel, maleSpeaker, femaleSpeaker) => {
    try {
      const response = await podcastService.generatePodcast({
        url: formUrl,
        languageLevel,
        maleSpeaker,
        femaleSpeaker
      });

      setIsReadyToPlay(true);

      if (!isMediaSourceSupported) return;

      try {
        const mediaSourceSetup = await setupMediaSource(response);
        if (mediaSourceSetup) {
          await processStream(mediaSourceSetup);
        }
      } catch (error) {
        console.error('MediaSource setup failed:', error);
      }

    } catch (error) {
      console.error('Error fetching audio stream:', error);
      alert(`Error: ${error.message}`);
      setIsProcessing(false);
      setIsPlayable(false);
      setIsReadyToPlay(false);
    }
  };

  const createPodcast = async ({
    url: formUrl,
    languageLevel,
    maleSpeaker,
    femaleSpeaker
  }) => {
    if (!formUrl.trim()) {
      alert('Please provide a URL.');
      return;
    }

    ReactGA.event({
      category: "Podcast",
      action: "Creation Attempted",
      label: languageLevel
    });

    if (!isProcessing) {
      setIsCreating(true);
      setIsProcessing(true);
      setProgressMessages([]);

      const hashInput = `${formUrl}|German|${maleSpeaker}|${femaleSpeaker}|${languageLevel}`;
      const hashId = await computeHash(hashInput);
      console.log('Generated hashId:', hashId);

      setNewPodcast({
        title: null,
        description: null,
        image_url: null,
        text_url: formUrl,
        language_level: languageLevel,
        male_speaker: maleSpeaker,
        female_speaker: femaleSpeaker,
        s3_url: null,
        objectID: hashId,
      });

      try {
        console.log('Starting polling...');
        poll(hashId);
        
        await startStreaming(formUrl, languageLevel, maleSpeaker, femaleSpeaker);
        
        ReactGA.event({
          category: "Podcast",
          action: "Creation Successful",
          label: languageLevel
        });
      } catch (error) {
        console.error('Creation error:', error);
        ReactGA.event({
          category: "Podcast",
          action: "Creation Failed",
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      if (pollTimeoutRef.current) {
        clearTimeout(pollTimeoutRef.current);
      }
    };
  }, []);

  return {
    isCreating,
    newPodcast,
    progressMessages,
    responseMetadata,
    isPlayable,
    isProcessing,
    isReadyToPlay,
    createPodcast
  };
}; 