const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const linkPreviewService = {
  async fetchPreview(url) {
    const response = await fetch(
      `${API_BASE_URL}/link_preview?url=${encodeURIComponent(url)}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    );

    if (!response.ok) {
      throw new Error(`Unable to fetch link preview (Error ${response.status})`);
    }

    return response.json();
  }
}; 