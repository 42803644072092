// components/Header.js

import React from 'react';

function Header() {
  return (
    <div className='text-center lg:text-left md:mx-auto max-w-screen-lg relative p-10 pt-8 lg:pt-16'>
      <h1 className='text-3xl font-bold'>KurzPodcasts</h1>
    </div>
  );
}

export default Header;
