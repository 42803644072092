import React from 'react';
import { useTranslation } from 'react-i18next';

const LANGUAGES = [
  { code: 'en', label: 'EN' },
  { code: 'de', label: 'DE' }
];

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const getButtonClass = (langCode) => `
    px-2 sm:px-3 py-1 
    rounded-full 
    text-xs sm:text-sm 
    font-medium 
    transition-colors
    ${i18n.language === langCode
      ? 'bg-rose-500 text-white'
      : 'text-gray-700 hover:bg-gray-100'
    }
  `;

  return (
    <div className="absolute top-4 right-4 z-50 lg:fixed">
      <div className="bg-white rounded-full shadow-md p-1 flex">
        {LANGUAGES.map(({ code, label }) => (
          <button
            key={code}
            onClick={() => i18n.changeLanguage(code)}
            className={getButtonClass(code)}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default LanguageSwitcher; 